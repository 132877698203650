import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/home';
import Settings from './pages/settings';
import ThreadPage from './pages/thread';
import FolderPage from './pages/folder';
import Layout from './components/layout';
import { ThreadFolderProvider } from './context/ThreadFolderContext';
import SupabaseServer from './components/supabase-server';
import Login from './pages/login';
import SignUp from './pages/signup';
import Landing from './pages/landing';
import { SignedIn, SignedOut } from "@clerk/clerk-react";

const App: React.FC = () => {
  return (
    <div className="bg-gray-100 min-h-screen"> {/* Added min-h-screen */}
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/sign-up" element={<Navigate to="/signup" replace />} />
          <Route path="/landing" element={<Landing />} />
          <Route
            path="*"
            element={
              <>
                <SignedIn>
                  <ThreadFolderProvider>
                    <Layout> {/* Ensure Layout doesn't override bg-gray-100 */}
                      <Routes>
                        <Route path="/" element={<Settings />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/thread/:thread_id" element={<ThreadPage />} />
                        <Route path="/folder/:folder_id" element={<FolderPage />} />
                        <Route path="/supabase-server-test" element={<SupabaseServer />} />
                      </Routes>
                    </Layout>
                  </ThreadFolderProvider>
                </SignedIn>
                <SignedOut>
                  <Navigate to="/login" replace />
                </SignedOut>
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;