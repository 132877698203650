import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Switch } from './switch';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/20/solid';

interface LockToggleProps {
  isLocked: boolean;
  onToggle: (passcode: string | null) => void;
}

const LockToggle: React.FC<LockToggleProps> = ({ isLocked, onToggle }) => {
  const [showKeypad, setShowKeypad] = useState(false);
  const [passcode, setPasscode] = useState('');
  const keypadRef = useRef<HTMLDivElement>(null);

  const handleNumberClick = (number: string) => {
    const newPasscode = (passcode + number).slice(0, 4);
    setPasscode(newPasscode);
    if (newPasscode.length === 4) {
      handleSubmit(newPasscode);
    }
  };

  const handleSubmit = useCallback((code: string) => {
    onToggle(code);
    setPasscode('');
    setShowKeypad(false);
  }, [onToggle]);

  const handleToggle = () => {
    if (isLocked) {
      setShowKeypad(true);
    } else {
      onToggle(null); // Lock without passcode
    }
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key >= '0' && event.key <= '9') {
      handleNumberClick(event.key);
    } else if (event.key === 'Enter') {
      handleSubmit(passcode);
    } else if (event.key === 'Backspace') {
      setPasscode(prev => prev.slice(0, -1));
    }
  }, [handleSubmit, passcode]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (keypadRef.current && !keypadRef.current.contains(event.target as Node)) {
        setShowKeypad(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={keypadRef}>
      <div 
        className="flex items-center bg-white dark:bg-zinc-800 rounded-full shadow-lg p-2 cursor-pointer"
        onClick={handleToggle}
      >
        <Switch
          checked={isLocked}
          onChange={() => {}}
          className="mr-2"
        />
        {isLocked ? (
          <LockClosedIcon className="w-5 h-5 text-zinc-500" />
        ) : (
          <LockOpenIcon className="w-5 h-5 text-zinc-500" />
        )}
      </div>
      {showKeypad && (
        <div className="absolute bottom-full right-0 mb-4 p-6 bg-white dark:bg-zinc-800 rounded-lg shadow-lg w-72">
          <div className="mb-4 flex justify-center">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="w-4 h-4 mx-2 rounded-full bg-gray-300 dark:bg-gray-600">
                {passcode.length > index && (
                  <div className="w-full h-full rounded-full bg-blue-500"></div>
                )}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-3 gap-4">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
              <button
                key={number}
                onClick={() => handleNumberClick(number.toString())}
                className="bg-zinc-100 dark:bg-zinc-700 rounded-full p-4 text-2xl font-semibold hover:bg-zinc-200 dark:hover:bg-zinc-600 transition-colors"
              >
                {number}
              </button>
            ))}
            <div className="col-span-1"></div>
            <button
              onClick={() => handleNumberClick('0')}
              className="bg-zinc-100 dark:bg-zinc-700 rounded-full p-4 text-2xl font-semibold hover:bg-zinc-200 dark:hover:bg-zinc-600 transition-colors"
            >
              0
            </button>
            <div className="col-span-1"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LockToggle;