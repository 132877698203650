import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import io from 'socket.io-client';
import { Divider } from './divider';
import { API_URL } from '../config';

interface ContextPanelProps {}

interface Content {
    text: string;
}

export const ContextPanel: React.FC<ContextPanelProps> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentList, setContentList] = useState<Content[]>([]);

    useEffect(() => {
        const socket = io(API_URL);

        socket.on('context_panel_content', (content) => {
            setContentList((prevContentList) => [...prevContentList, content]);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="fixed top-1/2 right-0 transform -translate-y-1/2 z-50 p-2 bg-black text-white rounded-l"
            >
                {isOpen ? <ChevronRightIcon className="h-6 w-6" /> : <ChevronLeftIcon className="h-6 w-6" />}
            </button>
            <Headless.Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className="fixed inset-0 z-40 flex justify-end"
            >
                <Headless.DialogBackdrop className="fixed inset-0 bg-black/30" />
                <Headless.DialogPanel
                    className={clsx(
                        'w-80 max-w-full h-full bg-black text-white p-4 shadow-lg',
                        'transition-transform transform',
                        isOpen ? 'translate-x-0' : 'translate-x-full'
                    )}
                >
                    <div className="context-panel">
                        <h1 className="text-xl font-bold mb-5">Orchestrator Context</h1>
                        <Divider className='text-white'/>
                        {contentList.map((content, index) => (
                            <div key={index} className="mb-4">
                                <p className="text-base">- {content.text}</p>
                            </div>
                        ))}
                    </div>
                </Headless.DialogPanel>
            </Headless.Dialog>
        </>
    );
};
