import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSession } from '@clerk/clerk-react';
import { API_URL } from '../config';

const Supabase = () => {
  const { session } = useSession();
  const [addresses, setAddresses] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        if (!session) {
          throw new Error("No active session");
        }

        const token = await session.getToken({ template: "supabase" });
        console.log(`token: ${token}`)
        if (!token) {
          throw new Error("Failed to get token from Clerk");
        }

        const response = await axios.get(`${API_URL}/api/addresses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(`response:`, response.data)
        setAddresses(response.data);
        console.log(`addresses:`, addresses)
      } catch (err) {
        setError((err as any).message);
      } finally {
        setLoading(false);
      }
    };

    if (session) {
      fetchAddresses();
    } else {
      setLoading(false);
    }
  }, [session]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <p>Error: {JSON.stringify(error, null, 2)}</p>;
  }

  return (
    <div>
      <h2>Addresses</h2>
      {!addresses.length ? (
        <p>No addresses</p>
      ) : (
        <ul>
          {addresses.map((address: any) => (
            <li key={address.id}>{address.content}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Supabase;