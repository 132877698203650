import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Heading } from '../components/heading';
import { Input } from '../components/input';
import { Button } from '../components/button';
import { Switch } from '../components/switch';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table';
import { PencilIcon, TrashIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { useClerkSupabaseClient } from '../context/ThreadFolderContext';
import { useAuth, useOrganization, useOrganizationList, OrganizationSwitcher, useUser, UserButton, useClerk, SignOutButton, CreateOrganization, SignUp } from '@clerk/clerk-react';
import { API_URL } from '../config';
import { Textarea } from '../components/textarea';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/dropdown';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { EnvelopeIcon, VideoCameraIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { motion, AnimatePresence } from 'framer-motion';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../components/alert';
import { Badge } from '../components/badge';

console.log(API_URL);

const CustomOrganizationSwitcher = (props: React.ComponentProps<typeof OrganizationSwitcher>) => (
  <OrganizationSwitcher 
    {...props}
    appearance={{
      elements: {
        organizationSwitcherTrigger: {
          '&[data-clerk-organization-switcher-trigger]': {
            '&[data-clerk-organization-switcher-trigger-empty]': {
              '&::before': {
                content: '"Please select an organization"',
              },
            },
          },
        },
      },
    }}
  />
);

const SettingsNew: React.FC = () => {
  const { organization, isLoaded } = useOrganization();
  const { setActive, isLoaded: listIsLoaded } = useOrganizationList();
  const [members, setMembers] = useState<any[]>([]);
  const [businessData, setBusinessData] = useState({
    name: '',
    details: ''
  });
  const [initialBusinessData, setInitialBusinessData] = useState({
    name: '',
    details: ''
  });
  const [isBusinessDataModified, setIsBusinessDataModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { user } = useUser();
  const { openUserProfile } = useClerk();
  const [isUserProfileHovered, setIsUserProfileHovered] = useState(false);

  const navigate = useNavigate();
  const client = useClerkSupabaseClient();
  const { getToken } = useAuth();
  const location = useLocation();

  const [integrations, setIntegrations] = useState({
    google: false,
    hubspot: false,
    xero: false,
  });

  const [connectedEmail, setConnectedEmail] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [xeroTenantId, setXeroTenantId] = useState<string | null>(null);

  const [dailyReports, setDailyReports] = useState({
    email: true,
    video: false,
    audio: false,
  });

  const [jobData, setJobData] = useState({
    title: '',
    description: ''
  });
  const [initialJobData, setInitialJobData] = useState({
    title: '',
    description: ''
  });
  const [isJobDataModified, setIsJobDataModified] = useState(false);
  const [isJobSaving, setIsJobSaving] = useState(false);
  const [jobSaveSuccess, setJobSaveSuccess] = useState(false);

  const [allSourcesConnected, setAllSourcesConnected] = useState(false);
  const [isHubspotAlertOpen, setIsHubspotAlertOpen] = useState(false);

  const hasOrganization = !!organization?.id;

  const dataSourcesRef = useRef<HTMLElement>(null);

  const [firstEmailSent, setFirstEmailSent] = useState<boolean>(false);
  const [showSetupSuccess, setShowSetupSuccess] = useState<boolean>(false);

  const [isSetupInProgress, setIsSetupInProgress] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  // Add these new state variables at the beginning of your component
  const [isTestEmailSending, setIsTestEmailSending] = useState(false);
  const [testEmailMessage, setTestEmailMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  const [customUserId, setCustomUserId] = useState('');
  const [isCustomTestEmailSending, setIsCustomTestEmailSending] = useState(false);
  const [customTestEmailMessage, setCustomTestEmailMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);

  const handleConnectClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (dataSourcesRef.current) {
      dataSourcesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (isLoaded && organization) {
      fetchMembers();
      syncOrganizationToSupabase(organization);
    }
  }, [isLoaded, organization]);

  useEffect(() => {
    fetchBusinessData();
    fetchJobData();
  }, []);

  useEffect(() => {
    fetchUserSettings();
  }, [user]);

  useEffect(() => {
    const allConnected = integrations.google && integrations.hubspot && integrations.xero;
    setAllSourcesConnected(allConnected);
  }, [integrations]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get('error');
    if (error) {
      setMessage(`Error: ${error}`); // Display error message
    }
  }, [location]);

  const fetchMembers = async () => {
    if (organization) {
      const memberList = await organization.getMemberships();
      setMembers(memberList.data);
    }
  };

  const syncOrganizationToSupabase = async (org: any) => {
    if (!org || !org.id || !org.name || !user?.id) return;

    try {
      const { data, error } = await client
        .from('org_info')
        .select('*')
        .eq('organization_id', org.id)
        .single();

      if (error && error.code !== 'PGRST116') {
        throw error;
      }

      const orgData = {
        organization_id: org.id,
        company_name: org.name,
        user_id: user.id,
        company_logo_url: org.imageUrl || null // Add this line to include the logo URL
      };

      if (data) {
        // Update existing record
        const { error: updateError } = await client
          .from('org_info')
          .update(orgData)
          .eq('organization_id', org.id);

        if (updateError) throw updateError;
      } else {
        // Insert new record
        const { error: insertError } = await client
          .from('org_info')
          .insert(orgData);

        if (insertError) throw insertError;
      }

      // Refresh business data after syncing
      await fetchBusinessData();
    } catch (error) {
      console.error('Error syncing organization:', error);
    }
  };

  const fetchBusinessData = async () => {
    try {
      if (!organization?.id) return;

      const { data, error } = await client
        .from('org_info')
        .select('company_name, company_details')
        .eq('organization_id', organization.id)
        .single();

      if (error) throw error;

      const fetchedData = {
        name: organization.name || data?.company_name || '',
        details: data?.company_details || ''
      };
      setBusinessData(fetchedData);
      setInitialBusinessData(fetchedData);
    } catch (error) {
      console.error('Error fetching business data:', error);
    }
  };

  const fetchJobData = async () => {
    try {
      const token = await getToken({ template: "supabase" });
      const { data, error } = await client
        .from('user_settings')
        .select('job_title, job_description')
        .eq('user_id', user?.id)
        .single();

      if (error) throw error;

      if (data) {
        const fetchedData = {
          title: data.job_title || '',
          description: data.job_description || ''
        };
        setJobData(fetchedData);
        setInitialJobData(fetchedData);
      }
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  const fetchUserSettings = async () => {
    if (!user) return;

    try {
      const { data, error } = await client
        .from('user_settings')
        .select('channel_email, channel_video, channel_audio, source_hubspot, first_email_sent, user_email')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setDailyReports({
          email: data.channel_email ?? true,
          video: data.channel_video ?? false,
          audio: data.channel_audio ?? false,
        });
        setIntegrations(prev => ({ ...prev, hubspot: data.source_hubspot }));
        setFirstEmailSent(data.first_email_sent ?? false);
        setUserEmail(data.user_email ?? null);
      } else {
        // If no data is found, set email to true by default
        setDailyReports(prev => ({ ...prev, email: true }));
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      // In case of error, still set email to true by default
      setDailyReports(prev => ({ ...prev, email: true }));
    }
  };

  useEffect(() => {
    const checkFullyConnectedState = async () => {
      if (
        integrations.google &&
        integrations.hubspot &&
        integrations.xero &&
        dailyReports.email &&
        !firstEmailSent &&
        !isSetupInProgress
      ) {
        try {
          setIsSetupInProgress(true);
          setShowSetupSuccess(true);

          const token = await getToken({ template: "supabase" });
          const response = await fetch(`${API_URL}/send-first-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          });

          if (!response.ok) {
            throw new Error('Failed to send first email');
          }

          setFirstEmailSent(true);
        } catch (error) {
          console.error('Error sending first email:', error);
          setShowSetupSuccess(false);
        } finally {
          setIsSetupInProgress(false);
        }
      }
    };

    checkFullyConnectedState();
  }, [integrations, dailyReports, firstEmailSent, isSetupInProgress]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setBusinessData(prevData => {
      const newData = { ...prevData, [id]: value };
      setIsBusinessDataModified(JSON.stringify(newData) !== JSON.stringify(initialBusinessData));
      return newData;
    });
  };

  const handleJobInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setJobData(prevData => {
      const newData = { ...prevData, [id]: value };
      setIsJobDataModified(JSON.stringify(newData) !== JSON.stringify(initialJobData));
      return newData;
    });
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      if (!organization?.id) {
        throw new Error('Organization ID is not available');
      }

      const token = await getToken({ template: "supabase" });
      // First, check if a record exists
      const { data: existingData, error: fetchError } = await client
        .from('org_info')
        .select('*')
        .eq('organization_id', organization.id)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      let result;
      if (existingData) {
        // If record exists, update it
        result = await client
          .from('org_info')
          .update({
            company_name: businessData.name,
            company_details: businessData.details,
          })
          .eq('organization_id', organization.id);
      } else {
        // If record doesn't exist, insert a new one
        result = await client
          .from('org_info')
          .insert({
            organization_id: organization.id,
            company_name: businessData.name,
            company_details: businessData.details,
          });
      }

      if (result.error) throw result.error;

      setInitialBusinessData(businessData);
      setIsBusinessDataModified(false);
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000); // Reset success state after 3 seconds
    } catch (error) {
      console.error('Error saving business data:', error);
      alert('Failed to save business data. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveJobSettings = async () => {
    setIsJobSaving(true);
    try {
      if (!user?.id) {
        throw new Error('User ID is not available');
      }

      const { error } = await client
        .from('user_settings')
        .upsert({
          user_id: user.id,
          job_title: jobData.title,
          job_description: jobData.description,
        }, { onConflict: 'user_id' });

      if (error) throw error;

      setInitialJobData(jobData);
      setIsJobDataModified(false);
      setJobSaveSuccess(true);
      setTimeout(() => setJobSaveSuccess(false), 3000); // Reset success state after 3 seconds
    } catch (error) {
      console.error('Error saving job data:', error);
      alert('Failed to save job data. Please try again.');
    } finally {
      setIsJobSaving(false);
    }
  };

  const handleIntegrationToggle = async (integration: keyof typeof integrations) => {
    if (isSetupInProgress) return;
    if (integration === 'hubspot' && user) {
      const newValue = !integrations.hubspot;
      setIntegrations(prev => ({ ...prev, hubspot: newValue }));

      try {
        const { error } = await client
          .from('user_settings')
          .upsert({
            user_id: user.id,
            source_hubspot: newValue,
          }, { onConflict: 'user_id' });

        if (error) {
          throw error;
        }

      } catch (error) {
        console.error(`Error updating ${integration} setting:`, error);
        // Revert the state if the update fails
        setIntegrations(prev => ({ ...prev, hubspot: !newValue }));
        alert(`Failed to update ${integration} setting. Please try again.`);
      }
    } else {
      setIntegrations(prev => ({ ...prev, [integration]: !prev[integration] }));
      console.log(`Toggled ${integration} integration`);
    }
  };

  const handleConnectGoogle = async () => {
    try {
      const response = await fetch(`${API_URL}/google-authorize`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getToken({ template: 'supabase' })}`
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to start Google authentication');
      }

      const data = await response.json();
      window.location.href = data.authorization_url;
    } catch (error) {
      console.error('Error connecting to Google account', error);
      navigate('/settings?error=connect_google_failed'); // Redirect with error
    }
  };

  const handleDisconnectGoogle = async () => {
    if (isSetupInProgress) return;
    try {
      const response = await fetch(`${API_URL}/google-disconnect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getToken({ template: 'supabase' })}`
        },
      });

      console.log('Google disconnect response:', response);

      if (!response.ok) {
        throw new Error('Failed to disconnect Google account');
      }

      setConnectedEmail(null);
      setIntegrations(prev => ({ ...prev, google: false }));
      setMessage('Google account disconnected successfully.');
      navigate('/settings'); // Success redirect
    } catch (error) {
      console.error('Error disconnecting Google account', error);
      navigate('/settings?error=disconnect_google_failed'); // Redirect with error
    }
  };

  const handleConnectXero = async () => {
    try {
      const supabaseAccessToken = await getToken({ template: 'supabase' });
      console.log('Supabase JWT Token:', supabaseAccessToken);

      const response = await fetch(`${API_URL}/api/xero/start-auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabaseAccessToken}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to start Xero authentication');
      }

      const { authUrl } = await response.json();
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error connecting to Xero:', error);
      navigate('/settings?error=connect_xero_failed'); // Redirect with error
    }
  };

  const handleDisconnectXero = async () => {
    if (isSetupInProgress) return;
    try {
      const token = await getToken({ template: "supabase" });
      if (!token) {
        console.error('No authorization token found');
        navigate('/settings'); // Success redirect
        return;
      }

      const response = await fetch(`${API_URL}/xero-disconnect`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to disconnect Xero');
      }

      setIntegrations(prev => ({ ...prev, xero: false }));
      navigate('/settings'); // Success redirect
    } catch (error) {
      console.error('Error disconnecting Xero:', error);
      navigate('/settings?error=disconnect_xero_failed'); // Redirect with error
    }
  };

  const handleDailyReportToggle = async (method: 'email' | 'video' | 'audio') => {
    if (isSetupInProgress || !allSourcesConnected || !user || !user.id) return;

    const newValue = !dailyReports[method];
    setDailyReports(prev => ({ ...prev, [method]: newValue }));

    try {
      const { error } = await client
        .from('user_settings')
        .upsert(
          {
            user_id: user.id,
            [`channel_${method}`]: newValue,
          },
          { 
            onConflict: 'user_id'
          }
        )
        .select();

      if (error) throw error;
    } catch (error) {
      console.error(`Error updating ${method} setting:`, error);
      // Revert the state if the update failed
      setDailyReports(prev => ({ ...prev, [method]: !newValue }));
      alert(`Failed to update ${method} setting. Please try again.`);
    }
  };

  useEffect(() => {
    const checkXeroConnection = async () => {
      try {
        const { data, error } = await client
          .from('xero_tokens')
          .select('*')
          .single();

        if (error) throw error;
        setIntegrations(prev => ({ ...prev, xero: !!data }));
        if (data && data.tenant_id) {
          setXeroTenantId(data.tenant_id);
        } else {
          setXeroTenantId(null);
        }
      } catch (error) {
        console.error('Error checking Xero connection:', error);
        setIntegrations(prev => ({ ...prev, xero: false }));
        setXeroTenantId(null);
      }
    };

    checkXeroConnection();
  }, [client]);

  useEffect(() => {
    const fetchAccountStatus = async () => {
      try {
        const { data: gmailData, error: gmailError } = await client.from('gmail_auth').select('*').single();

        if (gmailError) throw gmailError;

        if (gmailData) {
          setConnectedEmail(gmailData.email);
          setIntegrations(prev => ({ ...prev, google: true }));
        }
      } catch (error) {
        console.error('Error fetching account status:', error);
      }
    };

    fetchAccountStatus();
  }, []);

  const getRoleDisplayName = (role: string) => {
    switch (role) {
      case 'org:admin':
        return 'Admin';
      case 'org:member':
        return 'Member';
      default:
        return role;
    }
  };

  const handleHubspotConnect = () => {
    setIsHubspotAlertOpen(true);
  };

  const confirmHubspotConnect = () => {
    handleIntegrationToggle('hubspot');
    setIsHubspotAlertOpen(false);
  };

  const handleSendTestEmail = async () => {
    setIsTestEmailSending(true);
    setTestEmailMessage(null);
    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/send-test-email`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to send test email');
      }

      setTestEmailMessage({ type: 'success', text: 'Test email sent successfully!' });
    } catch (error) {
      console.error('Error sending test email:', error);
      setTestEmailMessage({ type: 'error', text: 'Failed to send test email. Please try again.' });
    } finally {
      setIsTestEmailSending(false);
    }
  };

  // Add this new function to handle sending a test email for a custom user ID
  const handleSendCustomTestEmail = async () => {
    setIsCustomTestEmailSending(true);
    setCustomTestEmailMessage(null);
    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/send-test-email/${customUserId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to send test email');
      }

      setCustomTestEmailMessage({ type: 'success', text: 'Test email sent successfully!' });
    } catch (error) {
      console.error('Error sending test email:', error);
      setCustomTestEmailMessage({ type: 'error', text: 'Failed to send test email. Please try again.' });
    } finally {
      setIsCustomTestEmailSending(false);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto py-8 px-4 relative pb-20 bg-gray-100">
      <div className="text-center mb-8">
        {hasOrganization ? (
          <>
            <div className="inline-block p-1 bg-gray-200 rounded-full mb-2">
              {isLoaded && organization ? (
                <img
                  src={organization.imageUrl}
                  alt={organization.name || "Organization logo"}
                  className="w-16 h-16 rounded-full"
                />
              ) : (
                <svg className="w-16 h-16 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              )}
            </div>
            <div className="flex items-center justify-center">
              <CustomOrganizationSwitcher 
                hidePersonal
                appearance={{
                  elements: {
                    rootBox: {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                    organizationSwitcherTrigger: {
                      padding: '6px 12px',
                      borderRadius: '6px',
                      backgroundColor: '#f3f4f6',
                      '&:hover': {
                        backgroundColor: '#e5e7eb',
                      },
                    },
                  },
                }}
              />
            </div>
          </>
        ) : (
          <Heading level={2} className="text-2xl font-semibold">Welcome</Heading>
        )}
      </div>

      { !allSourcesConnected && (
        <div className="flex bg-[#ffae14]/25 rounded-lg p-6 mb-6">
          <svg
            className="w-10 h-10 text-[#ffae14]"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
          <div className="ml-4 text-xl text-black">
            <span className="font-semibold">Welcome!</span>{' '}
            To get started, fill in your details and {' '}
            <a
              href="#data-sources"
              onClick={handleConnectClick}
              className="underline"
            >
              connect the data sources
            </a>{' '}
            below to begin receiving daily briefs
          </div>
        </div>
      )}

      {showSetupSuccess && (
        <div className="flex bg-green-100 rounded-lg p-4 mb-4">
          <svg className="w-5 h-5 text-green-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
          <p className="ml-3 text-sm text-green-700">
            {isSetupInProgress 
              ? `To get you started, ${userEmail} will receive the first daily brief in a few minutes and every day starting tomorrow`
              : userEmail
                ? `All set! Your first daily brief has been sent to ${userEmail}. You'll get them at 7:00AM ET everyday`
                : "All set! Your first daily brief is in your inbox, and you'll get them at 7:00AM ET everyday going forward"}
          </p>
        </div>
      )}

      <div className="space-y-6">
        {/* Business Information */}
        <section className="bg-white rounded-lg shadow p-6 relative">
          { !hasOrganization && (
            <div className="absolute inset-0 bg-gray-200 bg-opacity-50 z-10 rounded-lg flex flex-col items-center justify-center">
              <CreateOrganization />
            </div>
          )}
          <Heading level={2} className="text-xl font-semibold mb-4">Company</Heading>
          <p className="text-sm text-gray-500 mb-4">Enter your company details</p>
          {/* Company Name field is now hidden */}
          <div className="mb-6">
            <label htmlFor="details" className="block text-sm font-medium text-gray-700 mb-1">Company Description</label>
            <Textarea 
              id="details" 
              value={businessData.details}
              onChange={handleInputChange}
              placeholder="Tell us more about your business, strategy, and team structure" 
              className="w-full h-32"
              disabled={!hasOrganization}
            />
          </div>
          <AnimatePresence>
            {isBusinessDataModified && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="flex items-center overflow-hidden"
              >
                <Button 
                  color="dark" 
                  onClick={handleSaveSettings} 
                  disabled={isSaving}
                  className="relative"
                >
                  {isSaving ? 'Saving...' : 'Save'}
                  {saveSuccess && (
                    <CheckIcon className="w-5 h-5 text-green-500 absolute -right-6" />
                  )}
                </Button>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="mt-8 pt-6 border-t border-gray-200">
            <Heading level={3} className="text-lg font-semibold mb-4">Role</Heading>
            <p className="text-sm text-gray-500 mb-4">Enter your job details</p>
            <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Job Title</label>
              <Input 
                id="title" 
                value={jobData.title}
                onChange={handleJobInputChange}
                placeholder="Enter your job title" 
                className="w-full" 
                disabled={!hasOrganization}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Job Description</label>
              <Textarea 
                id="description" 
                value={jobData.description}
                onChange={handleJobInputChange}
                placeholder="Describe your job role and responsibilities on the team" 
                className="w-full h-32"
                disabled={!hasOrganization}
              />
            </div>
            <AnimatePresence>
              {isJobDataModified && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center overflow-hidden"
                >
                  <Button 
                    color="dark" 
                    onClick={handleSaveJobSettings} 
                    disabled={isJobSaving}
                    className="relative"
                  >
                    {isJobSaving ? 'Saving...' : 'Save'}
                    {jobSaveSuccess && (
                      <CheckIcon className="w-5 h-5 text-green-500 absolute -right-6" />
                    )}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </section>

        {/* Data Sources */}
        <section
          id="data-sources"
          ref={dataSourcesRef}
          className="bg-white rounded-lg shadow p-6"
        >
          <Heading level={2} className="text-xl font-semibold mb-4">Data Sources</Heading>
          <p className="text-sm text-gray-500 mb-4">Manage your connected data sources</p>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Source</TableHeader>
                <TableHeader>Description</TableHeader>
                <TableHeader>Actions</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Google Workspace</TableCell>
                <TableCell>Gmail, Google Calendar, Google Drive</TableCell>
                <TableCell>
                  <Button 
                    onClick={integrations.google ? handleDisconnectGoogle : handleConnectGoogle} 
                    color={integrations.google ? "red" : "dark"}
                    disabled={isSetupInProgress}
                  >
                    {integrations.google ? 'Disconnect' : 'Connect'}
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hubspot</TableCell>
                <TableCell>Deals, Contacts, Companies</TableCell>
                <TableCell>
                  <Button 
                    onClick={integrations.hubspot ? () => handleIntegrationToggle('hubspot') : handleHubspotConnect} 
                    color={integrations.hubspot ? "red" : "dark"}
                    disabled={isSetupInProgress}
                  >
                    {integrations.hubspot ? 'Disconnect' : 'Connect'}
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Xero</TableCell>
                <TableCell>Invoices, Bills, Transactions</TableCell>
                <TableCell>
                  <Button 
                    onClick={integrations.xero ? handleDisconnectXero : handleConnectXero} 
                    color={integrations.xero ? "red" : "dark"}
                    disabled={isSetupInProgress}
                  >
                    {integrations.xero ? 'Disconnect' : 'Connect'}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </section>

        {/* Daily Reports */}
        <section className="bg-white rounded-lg shadow p-6 relative">
          {!allSourcesConnected && (
            <div className="absolute inset-0 bg-gray-200 bg-opacity-50 z-10 rounded-lg" />
          )}
          <div className="flex justify-between items-center mb-4">
            <Heading level={2} className="text-xl font-semibold">Daily Briefs</Heading>
          </div>
          {allSourcesConnected && dailyReports.email ? (
            <div className="flex bg-gray-100 rounded-lg p-4 mb-4">
              <svg className="w-5 h-5 text-gray-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <p className="ml-3 text-sm text-gray-700">
                <span className="font-medium"></span> {user?.primaryEmailAddress?.emailAddress} will receive a daily brief every day at 7:00AM ET
              </p>
            </div>
          ) : allSourcesConnected && !dailyReports.email && (
            <div className="flex bg-red-100 rounded-lg p-4 mb-4">
              <svg className="w-5 h-5 text-red-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <p className="ml-3 text-sm text-red-700">
                <span className="font-medium">Oops!</span> Please turn on a channel to activate your daily briefs
              </p>
            </div>
          )}
          
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {[
              { method: 'email', icon: EnvelopeIcon, label: 'Email', comingSoon: false },
              { method: 'video', icon: VideoCameraIcon, label: 'Video', comingSoon: true },
              { method: 'audio', icon: SpeakerWaveIcon, label: 'Podcast', comingSoon: true },
            ].map(({ method, icon: Icon, label, comingSoon }) => (
              <div 
                key={method} 
                className={clsx(
                  "p-4 rounded-lg relative",
                  comingSoon || isSetupInProgress
                    ? "bg-gray-100 dark:bg-gray-800 cursor-not-allowed opacity-60" 
                    : `cursor-pointer transition-all duration-200 hover:shadow-md ${
                        dailyReports[method as keyof typeof dailyReports]
                          ? 'bg-blue-100 dark:bg-blue-900 shadow-md hover:bg-blue-200 dark:hover:bg-blue-800'
                          : 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700'
                      }`
                )}
                onClick={() => !comingSoon && !isSetupInProgress && allSourcesConnected && handleDailyReportToggle(method as keyof typeof dailyReports)}
              >
                <div className="flex flex-col items-center h-full justify-between">
                  <div className="flex flex-col items-center">
                    <Icon className={clsx(
                      "w-12 h-12 mb-2",
                      comingSoon
                        ? "text-gray-400 dark:text-gray-500"
                        : dailyReports[method as keyof typeof dailyReports]
                          ? "text-blue-600 dark:text-blue-400"
                          : "text-gray-600 dark:text-gray-400"
                    )} />
                    <p className={clsx(
                      "font-medium text-center",
                      comingSoon
                        ? "text-gray-400 dark:text-gray-500"
                        : dailyReports[method as keyof typeof dailyReports]
                          ? "text-blue-700 dark:text-blue-300"
                          : "text-gray-700 dark:text-gray-300"
                    )}>
                      {label}
                    </p>
                  </div>
                  <p className={clsx(
                    "text-sm mt-2 text-center",
                    comingSoon
                      ? "text-gray-400 dark:text-gray-500"
                      : dailyReports[method as keyof typeof dailyReports]
                        ? "text-blue-600 dark:text-blue-400"
                        : "text-gray-500 dark:text-gray-400"
                  )}>
                    {comingSoon ? 'Coming Soon' : (dailyReports[method as keyof typeof dailyReports] ? 'On' : 'Off')}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* User Accounts */}
        <section className="bg-white rounded-lg shadow p-6">
          <Heading level={2} className="text-xl font-semibold mb-4">User Accounts</Heading>
          <p className="text-sm text-gray-500 mb-4">Manage user accounts for your team</p>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Email</TableHeader>
                <TableHeader>Role</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>
                    <div className="flex items-center space-x-3">
                      <img 
                        src={member.publicUserData.imageUrl || '/default-avatar.png'} 
                        alt="Profile" 
                        className="w-8 h-8 rounded-full"
                      />
                      <span>{member.publicUserData.firstName} {member.publicUserData.lastName}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {member.publicUserData.identifier}
                  </TableCell>
                  <TableCell>
                    {getRoleDisplayName(member.role)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </section>

        {/* Test Email Button for specific users */}
        {(user?.id === "user_2mtGqmkYlVpg0JvW8yg6ImrR7vP" || user?.id === "user_2mtGU4MdaD4OMCWq2O0KyTjoSHy" || user?.id === "user_2nIP7WgIgmI3s9gkJVlRVWYBh9g") && (
          <section className="bg-white rounded-lg shadow p-6">
            <Heading level={2} className="text-xl font-semibold mb-4">Test Email</Heading>
            {testEmailMessage && (
              <div className={`mb-4 p-2 rounded ${testEmailMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                {testEmailMessage.text}
              </div>
            )}
            <Button 
              onClick={handleSendTestEmail} 
              color="dark" 
              disabled={isTestEmailSending}
            >
              {isTestEmailSending ? 'Sending...' : 'Send Test Email'}
            </Button>

            {/* New section for custom user ID test email */}
            <div className="mt-6">
              <Heading level={3} className="text-lg font-semibold mb-2">Send Test Email for Custom User ID</Heading>
              <div className="flex items-center space-x-2">
                <Input
                  type="text"
                  value={customUserId}
                  onChange={(e) => setCustomUserId(e.target.value)}
                  placeholder="Enter User ID"
                  className="flex-grow"
                />
                <Button
                  onClick={handleSendCustomTestEmail}
                  color="dark"
                  disabled={isCustomTestEmailSending || !customUserId}
                >
                  {isCustomTestEmailSending ? 'Sending...' : 'Send'}
                </Button>
              </div>
              {customTestEmailMessage && (
                <div className={`mt-2 p-2 rounded ${customTestEmailMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                  {customTestEmailMessage.text}
                </div>
              )}
            </div>
          </section>
        )}

        {/* User Profile and Sign Out at the bottom */}
        <div className="mt-8 border-t pt-8 flex justify-between items-center">
          <div 
            className={clsx(
              "flex min-w-0 items-center gap-3 cursor-pointer rounded-lg p-2 transition-colors duration-200",
              isUserProfileHovered ? "bg-zinc-100 dark:bg-zinc-800" : ""
            )}
            onClick={() => openUserProfile()}
            onMouseEnter={() => setIsUserProfileHovered(true)}
            onMouseLeave={() => setIsUserProfileHovered(false)}
          >
            <UserButton afterSignOutUrl="/" />
            <span className="min-w-0 flex-1">
              <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                {user?.firstName} {user?.lastName}
              </span>
              <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                {user?.primaryEmailAddress?.emailAddress}
              </span>
            </span>
          </div>

          <SignOutButton>
            <Button color="red">Sign Out</Button>
          </SignOutButton>
        </div>
      </div>

      {/* Hubspot Connect Alert */}
      <Alert open={isHubspotAlertOpen} onClose={() => setIsHubspotAlertOpen(false)}>
        <AlertTitle>Connect Hubspot</AlertTitle>
        <AlertDescription>
          Are you sure you want to activate your company's Hubspot key? This will allow access to your Hubspot data.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsHubspotAlertOpen(false)}>
            Cancel
          </Button>
          <Button onClick={confirmHubspotConnect}>
            Connect
          </Button>
        </AlertActions>
      </Alert>
    </div>
  );
};

export default SettingsNew;