import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { useAuth } from '@clerk/clerk-react';

// Add clerk to Window to avoid type errors
declare global {
  interface Window {
    Clerk: any;
  }
}

export function useClerkSupabaseClient() {
  const { getToken } = useAuth();
  
  const client = useMemo(() => createClient(
    process.env.REACT_APP_SUPABASE_URL!,
    process.env.REACT_APP_SUPABASE_KEY!,
    {
      global: {
        fetch: async (url, options = {}) => {
          const token = await getToken({ template: "supabase" });
          
          if (!token) {
            throw new Error("No active session or failed to get Clerk token");
          }

          const headers = new Headers(options?.headers);
          headers.set("Authorization", `Bearer ${token}`);

          return fetch(url, {
            ...options,
            headers,
          });
        },
      },
    }
  ), [getToken]);

  return client;
}

export interface Thread {
  id: string;
  title: string;
  folder_id: string | null;
}

export interface Folder {
  id: string;
  name: string;
  description: string;
}

interface ThreadFolderContextType {
  threads: Thread[];
  setThreads: React.Dispatch<React.SetStateAction<Thread[]>>;
  folders: Folder[];
  setFolders: React.Dispatch<React.SetStateAction<Folder[]>>;
  fetchThreads: () => Promise<void>;
  fetchFolders: () => Promise<void>;
  addThread: (thread: Omit<Thread, 'id'>) => Promise<Thread | null>;
  updateThread: (thread: Thread) => Promise<void>;
  deleteThread: (thread: Thread) => Promise<boolean>;
  addFolder: (folder: Omit<Folder, 'id'>) => Promise<Folder | null>;
  updateFolder: (folder: Folder) => Promise<void>;
  deleteFolder: (id: string) => Promise<void>;
}

const ThreadFolderContext = createContext<ThreadFolderContextType | undefined>(undefined);

export const ThreadFolderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [threads, setThreads] = useState<Thread[]>([]);
  const [folders, setFolders] = useState<Folder[]>([]);
  const { isLoaded, isSignedIn } = useAuth();
  const client = useClerkSupabaseClient();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      fetchThreadsAndFolders();
    }
  }, [isLoaded, isSignedIn]);

  const fetchThreadsAndFolders = async () => {
    await Promise.all([fetchThreads(), fetchFolders()]);
  };

  const fetchThreads = async () => {
    if (threads.length > 0) return; // Skip if we already have threads
    const { data, error } = await client.from("thread").select();
    if (!error && data) {
      setThreads(data);
    } else {
      console.error("Error fetching threads:", error);
    }
  };

  const fetchFolders = async () => {
    if (folders.length > 0) return; // Skip if we already have folders
    const { data, error } = await client.from("folder").select();
    if (!error && data) {
      setFolders(data);
    } else {
      console.error("Error fetching folders:", error);
    }
  };

  const addThread = async (thread: Omit<Thread, 'id'>): Promise<Thread | null> => {
    try {
      const { data, error } = await client.from("thread").insert(thread).select().single();
      if (error) {
        console.error("Supabase error:", error);
        return null;
      }
      if (data) {
        setThreads(prev => [...prev, data]);
        return data;
      }
    } catch (e) {
      console.error("Error adding thread:", e);
    }
    return null;
  };

  const updateThread = async (thread: Thread) => {
    const { error } = await client.from("thread").update(thread).eq('id', thread.id);
    if (!error) {
      setThreads(prev => prev.map(t => t.id === thread.id ? thread : t));
    }
  };

  const deleteThread = async (thread: Thread): Promise<boolean> => {
    console.log(`Attempting to delete thread with id: ${thread.id}`);
    try {
      const { data, error } = await client
        .from("thread")
        .delete()
        .eq('id', thread.id)
        .select();

      console.log("Supabase delete response:", { data, error });

      if (error) {
        console.error("Supabase error:", error);
        return false;
      }

      if (data && data.length === 0) {
        console.warn("No rows were deleted. The thread might not exist.");
        return false;
      }

      console.log("Thread deleted successfully. Updating state...");
      const oldThreadsCount = threads.length;
      setThreads(prev => {
        const newThreads = prev.filter(t => t.id !== thread.id);
        console.log(`Threads count: before=${oldThreadsCount}, after=${newThreads.length}`);
        return newThreads;
      });

      return true;
    } catch (e) {
      console.error("Unexpected error deleting thread:", e);
    }
    return false;
  };

  const addFolder = async (folder: Omit<Folder, 'id'>): Promise<Folder | null> => {
    const { data, error } = await client.from("folder").insert(folder).select().single();
    if (!error && data) {
      setFolders(prev => [...prev, data]);
      return data;
    }
    return null;
  };

  const updateFolder = async (folder: Folder) => {
    const { error } = await client.from("folder").update(folder).eq('id', folder.id);
    if (!error) {
      setFolders(prev => prev.map(f => f.id === folder.id ? folder : f));
    }
  };

  const deleteFolder = async (id: string) => {
    const { error } = await client.from("folder").delete().eq('id', id);
    if (!error) {
      setFolders(prev => prev.filter(f => f.id !== id));
    }
  };

  return (
    <ThreadFolderContext.Provider value={{
      threads,
      setThreads,
      folders,
      setFolders,
      fetchThreads,
      fetchFolders,
      addThread,
      updateThread,
      deleteThread,
      addFolder,
      updateFolder,
      deleteFolder
    }}>
      {children}
    </ThreadFolderContext.Provider>
  );
};

export const useThreadFolderContext = () => {
  const context = useContext(ThreadFolderContext);
  if (context === undefined) {
    throw new Error('useThreadFolderContext must be used within a ThreadFolderProvider');
  }
  return context;
};