import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useThreadFolderContext } from '../context/ThreadFolderContext';
import { Avatar } from './avatar';
import betakitLogo from '../assets/betakit_logo.jpg';
import userProfilePhoto from '../assets/siri_headshot.png';
import { SignIn, SignedIn, SignedOut, SignInButton, UserButton, useSession, OrganizationSwitcher, useClerk } from "@clerk/clerk-react";
import { Label } from './fieldset';
import { Badge } from './badge';
import { Button } from './button';
import clsx from 'clsx';  // Add this import
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from './dropdown';
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from './navbar';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from './sidebar';
import { SidebarLayout } from './sidebar-layout';
import { Switch } from './switch';
import * as Headless from '@headlessui/react';
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon as PlusIconLarge,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid';
import {
  Cog6ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  ChevronUpIcon,
  TrashIcon,
  PlusIcon as PlusIconSmall,
} from '@heroicons/react/20/solid';
import { API_URL } from '../config';
import LockToggle from './LockToggle';

interface LayoutProps {
  children: React.ReactNode;
}

export interface Thread {
  id: string;
  title: string;
  folder_id: string | null;
  messages: {
    content: string;
    timestamp: string;
  }[];
}

export interface Folder {
  id: string;
  name: string;
  description: string;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { threads, setThreads, folders, setFolders, fetchThreads, fetchFolders, addThread, updateThread, deleteThread, addFolder, updateFolder, deleteFolder } = useThreadFolderContext();
  const [editingFolderId, setEditingFolderId] = useState<string | null>(null);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const navigate = useNavigate();
  const [expandedFolders, setExpandedFolders] = useState<{ [key: string]: boolean }>({});
  const [userName, setUserName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const { session } = useSession();
  const { openUserProfile } = useClerk();
  const [isUserProfileHovered, setIsUserProfileHovered] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const location = useLocation();

  const isSettingsPage = location.pathname === '/settings';

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (!session) {
          console.log("No active session");
          return;
        }

        const user = await session.user;
        if (!user) {
          throw new Error("Failed to get user from Clerk session");
        }

        setUserName(`${user.firstName} ${user.lastName}`);
        setUserEmail(user.emailAddresses[0]?.emailAddress || '');
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    if (session) {
      fetchUserInfo();
    }
  }, [session]);

  const toggleFolder = (folderId: string) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
  };

  const handleNewFolder = useCallback(async () => {
    try {
      const newFolder = { name: 'New Folder', description: '' };
      const newFolderData = await addFolder(newFolder);
      if (newFolderData) {
        setEditingFolderId(newFolderData.id);
        setNewFolderName(newFolderData.name);
      }
    } catch (error) {
      console.error('Failed to create new folder:', error);
    }
  }, [addFolder]);

  const handleFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(e.target.value);
  };

  const handleFolderNameSubmit = async (folderId: string) => {
    try {
      const folder = folders.find(f => f.id === folderId);
      if (folder) {
        await updateFolder({ id: folderId, name: newFolderName, description: folder.description });
        setEditingFolderId(null);
      }
    } catch (error) {
      console.error('Failed to update folder name:', error);
    }
  };

  const handleNewThread = useCallback(async () => {
    try {
      if (!session) {
        throw new Error("No active session");
      }

      const token = await session.getToken({ template: "supabase" });
      if (!token) {
        throw new Error("Failed to get token from Clerk");
      }

      const response = await fetch(`${API_URL}/api/new_thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const newThreadId = data.thread_id;

      setThreads((prevThreads) => [
        ...prevThreads,
        { id: newThreadId, title: 'New Thread', folder_id: null, messages: [] },
      ]);

      navigate(`/thread/${newThreadId}`);
    } catch (error) {
      console.error('Failed to create new thread:', error);
    }
  }, [navigate, setThreads, session]);

  const handleDeleteThread = async (thread: Thread) => {
    try {
      await deleteThread(thread);
      navigate('/');
    } catch (error) {
      console.error('Failed to delete thread:', error);
    }
  };
  
  const memoizedThreads = useMemo(() => threads, [threads]);
  const memoizedFolders = useMemo(() => folders, [folders]);

  const getLastMessagePreview = (thread: Thread) => {
    if (thread.messages.length > 0) {
      const lastMessage = thread.messages[thread.messages.length - 1];
      return truncateText(lastMessage.content, 250);
    }
    return 'No messages yet';
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const handleToggleLock = useCallback((passcode: string | null) => {
    if (isLocked) {
      // Unlocking
      if (passcode === '8888') {
        setIsLocked(false);
      } else {
        alert('Incorrect passcode. Please try again.');
      }
    } else {
      // Locking - no passcode required
      setIsLocked(true);
      if (!isSettingsPage) {
        navigate('/settings');
      }
    }
  }, [isLocked, isSettingsPage, navigate]);

  useEffect(() => {
    if (isLocked && !isSettingsPage) {
      navigate('/settings');
    }
  }, [isLocked, isSettingsPage, navigate]);

  // New useEffect to handle initial load
  useEffect(() => {
    if (isLocked && !isSettingsPage) {
      navigate('/settings');
    }
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className={`relative ${isLocked ? 'layout-locked' : ''}`}>
      <SidebarLayout
        navbar={!isLocked ? (
          <Navbar>
            <NavbarSpacer />
            <NavbarSection>
              <NavbarItem href="/search" aria-label="Search">
                <MagnifyingGlassIcon />
              </NavbarItem>
              <NavbarItem href="/inbox" aria-label="Inbox">
                <InboxIcon />
              </NavbarItem>
              <Dropdown>
                  <DropdownButton as={NavbarItem}>
                      <SignedIn>
                      <UserButton />
                      </SignedIn>
                  </DropdownButton>
              </Dropdown>
            </NavbarSection>
          </Navbar>
        ) : null}
        sidebar={!isLocked ? (
          <Sidebar>
            <SidebarHeader>
              <div className="mb-2">
                <OrganizationSwitcher />
              </div>
              <SidebarSection className="max-lg:hidden">
                <SidebarItem href="/search">
                  <MagnifyingGlassIcon />
                  <SidebarLabel>Search</SidebarLabel>
                </SidebarItem>
                <SidebarItem onClick={() => navigate('/')}>
                  <HomeIcon />
                  <SidebarLabel>Home</SidebarLabel>
                </SidebarItem>
                <SidebarItem onClick={() => navigate('/settings')}>
                  <Cog6ToothIcon />
                  <SidebarLabel>Settings</SidebarLabel>
                </SidebarItem>
              </SidebarSection>
            </SidebarHeader>
            <SidebarBody>
              <SidebarSection>
                <SidebarHeading className="flex justify-between items-center">
                  <span>Inbox</span>
                  <PlusIconSmall className="ml-2 text-zinc-500 cursor-pointer w-4 h-4" onClick={handleNewThread} />
                </SidebarHeading>
                {memoizedThreads.filter((thread) => !thread.folder_id).map((thread, index) => (
                  <SidebarItem key={index} className="relative group" onClick={() => navigate(`/thread/${thread.id}`)}>
                    <SidebarLabel className="font-light">{thread.title}</SidebarLabel>
                    <TrashIcon className="w-5 h-5 text-gray-500 absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteThread(thread as Thread);
                      }}
                    />
                  </SidebarItem>
                ))}
              </SidebarSection>
              <SidebarSection>
                <SidebarHeading className="flex justify-between items-center">
                  <span>Projects</span>
                  <PlusIconSmall className="ml-2 text-zinc-500 cursor-pointer w-4 h-4" onClick={handleNewFolder} />
                </SidebarHeading>
                {memoizedFolders.map((folder, index) => (
                  <div key={index} className="flex flex-col">
                    <SidebarItem 
                      onClick={() => navigate(`/folder/${folder.id}`)} 
                      className="flex items-center"
                      threadCount={memoizedThreads.filter(thread => thread.folder_id === folder.id).length}
                      isExpanded={expandedFolders[folder.id]}
                      onToggleExpand={() => toggleFolder(folder.id)}
                      isFolder={true}
                    >
                      {editingFolderId === folder.id ? (
                        <input
                          type="text"
                          value={newFolderName}
                          onChange={handleFolderNameChange}
                          onBlur={() => handleFolderNameSubmit(folder.id)}
                          onKeyDown={(e) => e.key === 'Enter' && handleFolderNameSubmit(folder.id)}
                          autoFocus
                          className="text-base font-bold text-zinc-950 sm:text-base"
                          onClick={(e) => e.stopPropagation()}
                        />
                      ) : (
                        <SidebarLabel className="font-bold">{folder.name}</SidebarLabel>
                      )}
                    </SidebarItem>
                    {expandedFolders[folder.id] && (
                      <div className="overflow-hidden flex flex-col gap-0.5">
                        {memoizedThreads.filter((thread) => thread.folder_id === folder.id).map((thread, index) => (
                          <SidebarItem key={index} onClick={() => navigate(`/thread/${thread.id}`)} className="pl-4 relative group">
                            <SidebarLabel className="font-light">{thread.title}</SidebarLabel>
                            <TrashIcon className="w-5 h-5 text-gray-500 absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteThread(thread as Thread);
                              }}
                            />
                          </SidebarItem>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </SidebarSection>
              <SidebarSpacer />
              <SidebarSection>
                  <SidebarItem href="/help">
                      <QuestionMarkCircleIcon />
                      <SidebarLabel>Help</SidebarLabel>
                  </SidebarItem>
                  <SidebarItem href="/about">
                      <SparklesIcon />
                      <SidebarLabel>About</SidebarLabel>
                  </SidebarItem>
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter>
              <SidebarSection>
                <SignedIn>
                  <div 
                    className={clsx(
                      "flex min-w-0 items-center gap-3 cursor-pointer rounded-lg p-2 transition-colors duration-200",
                      isUserProfileHovered ? "bg-zinc-100 hover:bg-zinc-200 dark:bg-zinc-800 dark:hover:bg-zinc-700" : "hover:bg-zinc-100 dark:hover:bg-zinc-800"
                    )}
                    onClick={() => openUserProfile()}
                    onMouseEnter={() => setIsUserProfileHovered(true)}
                    onMouseLeave={() => setIsUserProfileHovered(false)}
                  >
                    <UserButton afterSignOutUrl="/" />
                    <span className="min-w-0 flex-1">
                      <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{userName}</span>
                      <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                        {userEmail}
                      </span>
                    </span>
                  </div>
                </SignedIn>
                <SignedOut>
                  <SignInButton />
                </SignedOut>
              </SidebarSection>
            </SidebarFooter>
          </Sidebar>
        ) : null}
        isLocked={isLocked}
      >
        <div className="flex justify-center bg-gray-100">
          {children}
        </div>
        <div className="fixed bottom-4 right-4 z-50">
          <LockToggle
            isLocked={isLocked}
            onToggle={handleToggleLock}
          />
        </div>
      </SidebarLayout>
    </div>
  );
};

export default Layout;