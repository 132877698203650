import React, { useState, useEffect, useRef, FormEvent, KeyboardEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import loadingSpinner from '../assets/typing_dots.gif';
import { PaperAirplaneIcon, PencilIcon, TrashIcon, ChevronDownIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { Button } from '../components/button';
import { Textarea } from '../components/textarea';
import { Divider } from '../components/divider';
import { Field, Label } from '../components/fieldset';
import { Badge } from '../components/badge';
import { Heading, Subheading } from '../components/heading';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/dropdown';
import { useThreadFolderContext } from '../context/ThreadFolderContext';
import { ContextPanel } from '../components/contextpanel';
import io from 'socket.io-client';
import { API_URL } from '../config';

interface Message {
    sender: 'user' | 'ai';
    text: string;
}

const Thread: React.FC = () => {
    const { threads, setThreads, folders } = useThreadFolderContext();
    const { thread_id } = useParams<{ thread_id: string }>();
    const navigate = useNavigate();
    const [input, setInput] = useState<string>('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [threadTitle, setThreadTitle] = useState<string>(''); // State for thread title
    const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false); // State for editing title
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const fixedElementRef = useRef<HTMLDivElement | null>(null);
    const [containerHeight, setContainerHeight] = useState<string>('calc(100vh - 10rem - 2rem)');
    const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [lastContextPayload, setLastContextPayload] = useState<string>('');

    useEffect(() => {
        const fetchThreadInfo = async () => {
            try {
                const response = await fetch(`${API_URL}/api/get_thread_info/${thread_id}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                const formattedMessages = await Promise.all(data.messages.map(async (msg: any) => ({
                    sender: msg.role === 'user' ? 'user' : 'assistant',
                    text: await marked.parse(msg.content) // Parse the markdown content to HTML
                })));
                setMessages(formattedMessages.reverse());
                setThreadTitle(data.title); // Set the thread title
                setSelectedFolder(data.folder_id); // Set the folder ID
            } catch (error) {
                console.error("Failed to fetch thread info:", error);
            }
        };

        if (thread_id) {
            fetchThreadInfo();
        }
    }, [thread_id]);

    useEffect(() => {
        const socket = io(API_URL); // Use API_URL environment variable for production calls

        socket.on('context_panel_content', (content) => {
            setLastContextPayload(content.text);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isEditingTitle && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditingTitle]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (fixedElementRef.current) {
            const fixedElementHeight = fixedElementRef.current.offsetHeight;
            setContainerHeight(`calc(100vh - ${fixedElementHeight}px - 9rem)`);
        }
    }, []);

    const handleFolderChange = async (folderId: string | null) => {
        try {
            const response = await fetch(`${API_URL}/api/update_thread_folder/${thread_id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ folder_id: folderId }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setSelectedFolder(folderId);
            // Update the sidebar to reflect the change
            const updatedThreads = threads.map(thread => 
                thread.id === thread_id ? { ...thread, folder_id: folderId } : thread
            );
            setThreads(updatedThreads);
        } catch (error) {
            console.error('Failed to update thread folder:', error);
        }
    };

    const handleTitleEdit = () => {
        setIsEditingTitle(true);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setThreadTitle(e.target.value);
    };

    const handleTitleSubmit = async () => {
        try {
            const response = await fetch(`${API_URL}/api/update_thread_name/${thread_id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ new_name: threadTitle })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Update the sidebar title
            const updatedThreads = threads.map(thread =>
                thread.id === thread_id ? { ...thread, title: threadTitle } : thread
            );
            setThreads(updatedThreads);

            setIsEditingTitle(false);
        } catch (error) {
            console.error("Failed to update thread title:", error);
        }
    };

    const handleDeleteThread = async () => {
        try {
            const response = await fetch(`${API_URL}/api/delete_thread/${thread_id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            setThreads(prevThreads => prevThreads.filter(thread => thread.id !== thread_id));

            // Navigate away from the thread page
            navigate('/');
        } catch (error) {
            console.error('Failed to delete thread:', error);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        sendMessage();
    };

    const sendMessage = async () => {
        setIsLoading(true);

        const userMessage = input;
        setMessages((prevMessages) => [...prevMessages, { sender: 'user', text: userMessage }]);
        setInput('');

        try {
            const response = await fetch(`${API_URL}/api/orchestrate`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ message: userMessage, thread_id })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body?.getReader();
            const decoder = new TextDecoder();

            let aiMessageId: number | null = null;
            let aiResponse = "";

            while (true) {
                const { done, value } = await reader?.read()!;
                if (done) break;

                const chunk = decoder.decode(value);
                aiResponse += chunk;

                const parsedResponse = await marked.parse(aiResponse);

                setMessages((prevMessages) => {
                    let newMessages = [...prevMessages];
                    if (aiMessageId === null) {
                        aiMessageId = newMessages.length;
                        newMessages.push({ sender: 'ai', text: parsedResponse });
                    } else {
                        newMessages[aiMessageId] = { sender: 'ai', text: parsedResponse };
                    }
                    return newMessages;
                });
            }
        } catch (error) {
            console.error("Failed to send message:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.key === 'Enter' && (e.metaKey || e.ctrlKey))) {
            e.preventDefault();
            handleSubmit(e as unknown as FormEvent<HTMLFormElement>); // Cast to FormEvent
        }
    };

    return (
        <>
            <div className="flex flex-col w-full">
                <ContextPanel />
                <div className="flex items-center">
                    <Dropdown>
                        <DropdownButton as="div" className="cursor-pointer flex items-center">
                            <Heading className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8 dark:text-white">
                                {selectedFolder ? folders.find(folder => folder.id === selectedFolder)?.name : 'Inbox'}
                            </Heading>
                            <ChevronDownIcon className="mr-2 h-5 w-5 text-gray-500" />
                        </DropdownButton>
                        <DropdownMenu>
                            <DropdownItem onClick={() => handleFolderChange(null)}>Inbox</DropdownItem>
                            {folders.map(folder => (
                                <DropdownItem key={folder.id} onClick={() => handleFolderChange(folder.id)}>
                                    {folder.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    {isEditingTitle ? (
                        <input
                            type="text"
                            value={threadTitle}
                            onChange={handleTitleChange}
                            onBlur={handleTitleSubmit}
                            onKeyDown={(e) => e.key === 'Enter' && handleTitleSubmit()}
                            className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8 dark:text-white"
                            ref={inputRef}
                        />
                    ) : (
                        <h1 className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8 dark:text-white">
                            {threadTitle}
                        </h1>
                    )}
                    <div className="ml-auto flex items-center space-x-2">
                        <Dropdown>
                            <DropdownButton as="div" className="cursor-pointer">
                                <EllipsisHorizontalIcon className="w-5 h-5 text-gray-500" />
                            </DropdownButton>
                            <DropdownMenu>
                                <DropdownItem onClick={handleTitleEdit}>
                                    <PencilIcon className="w-5 h-5 text-gray-500 mr-2" />
                                    Edit
                                </DropdownItem>
                                <DropdownItem onClick={handleDeleteThread}>
                                    <TrashIcon className="w-5 h-5 text-gray-500 mr-2" />
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <Divider soft className="my-4" />
                <div className="overflow-y-auto rounded-md flex flex-col" style={{ height: containerHeight }} ref={chatContainerRef}>
                    {messages.map((msg, index) => (
                        <div key={index} className={`p-3 mb-2 text-sm/7 ${msg.sender === 'user' ? 'bg-gray-100 rounded-xl text-black self-end' : 'bg-white text-black rounded-xl self-start'}`}>
                            <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                        </div>
                    ))}
                    {isLoading && (
                        <div className="self-start">
                            <Badge color="zinc" className="flex items-center">
                                <img src={loadingSpinner} alt="Loading..." className="h-1 w-4 inline-block ml-2" /> {lastContextPayload}
                            </Badge>
                        </div>
                    )}
                </div>
                
            </div>
            <div className="fixed bottom-5 max-w-6xl w-11/12 sm:w-8/12 py-5 px-5 rounded-xl  bg-gray-100" ref={fixedElementRef}>
                <form onSubmit={handleSubmit} className=" flex items-center">
                    <Field className="rounded-md w-11/12 focus:outline-none">
                        {/* <Label>Message BetaKit</Label> */}
                        <Textarea
                            id="input"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={handleKeyPress}
                            placeholder="Type your message here..."
                            className = ""
                        />
                    </Field>
                    <div className="justify-end ml-2 w-1/12 sm:w-full items-center">
                        <Button type="submit" className="" disabled={isLoading}>
                            <PaperAirplaneIcon />cmd+k
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Thread;