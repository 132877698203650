import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { PencilIcon, TrashIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { Button } from '../components/button';
import { Divider } from '../components/divider';
import { Field, Label } from '../components/fieldset';
import { useThreadFolderContext } from '../context/ThreadFolderContext';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/dropdown';
import { Heading, Subheading } from '../components/heading';
import { API_URL } from '../config';

interface Thread {
    id: string;
    title: string;
    folder_id: string | null;
}

const Folder: React.FC = () => {
    const { folders, threads, setFolders, setThreads, deleteThread } = useThreadFolderContext();
    const { folder_id } = useParams<{ folder_id: string }>();
    const [folderName, setFolderName] = useState<string>('');
    const [folderDescription, setFolderDescription] = useState<string>('');
    const [isEditingName, setIsEditingName] = useState<boolean>(false);
    const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const folder = folders.find(f => f.id === folder_id);
        if (folder) {
            setFolderName(folder.name);
            setFolderDescription(folder.description || '');
        }
    }, [folder_id, folders]);

    useEffect(() => {
        if (isEditingName && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditingName]);

    useEffect(() => {
        if (isEditingDescription && descriptionRef.current) {
            descriptionRef.current.focus();
        }
    }, [isEditingDescription]);

    const handleNameEdit = () => {
        setIsEditingName(true);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFolderName(e.target.value);
    };

    const handleNameSubmit = async () => {
        try {
            const response = await fetch(`${API_URL}/api/update_folder_name/${folder_id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ new_name: folderName })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setFolders(prevFolders =>
                prevFolders.map(folder =>
                    folder.id === folder_id ? { ...folder, name: folderName } : folder
                )
            );

            setIsEditingName(false);
        } catch (error) {
            console.error('Failed to update folder name:', error);
        }
    };

    const handleDescriptionEdit = () => {
        setIsEditingDescription(true);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFolderDescription(e.target.value);
    };

    const handleDescriptionSubmit = async () => {
        try {
            const response = await fetch(`${API_URL}/api/update_folder_description/${folder_id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ description: folderDescription })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setFolders(prevFolders =>
                prevFolders.map(folder =>
                    folder.id === folder_id ? { ...folder, description: folderDescription } : folder
                )
            );

            setIsEditingDescription(false);
        } catch (error) {
            console.error('Failed to update folder description:', error);
        }
    };

    const handleDeleteFolder = async () => {
        try {
            const response = await fetch(`${API_URL}/api/delete_folder/${folder_id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setFolders(prevFolders => prevFolders.filter(folder => folder.id !== folder_id));
        } catch (error) {
            console.error('Failed to delete folder:', error);
        }
    };

    const relatedThreads = threads.filter(thread => thread.folder_id === folder_id);

    const handleDeleteThread = async (thread: Thread) => {
        try {
            const response = await fetch(`${API_URL}/api/delete_thread/${thread.id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            // Update local state
            setThreads(prevThreads => prevThreads.filter(t => t.id !== thread.id));
        } catch (error) {
            console.error('Failed to delete thread:', error);
        }
    };

    return (
        <div className="flex flex-col w-full max-w-3xl mx-auto px-4">
            <div className="flex items-center justify-between mb-4">
                <div className="flex-grow">
                    {isEditingName ? (
                        <input
                            type="text"
                            value={folderName}
                            onChange={handleNameChange}
                            onBlur={handleNameSubmit}
                            onKeyDown={(e) => e.key === 'Enter' && handleNameSubmit()}
                            className="text-2xl font-semibold text-zinc-950 dark:text-white w-full"
                            ref={inputRef}
                        />
                    ) : (
                        <Heading>{folderName}</Heading>
                    )}
                </div>
                <Dropdown>
                    <DropdownButton as="div" className="h-8 w-8 text-gray-500 cursor-pointer flex items-center justify-center">
                        <EllipsisHorizontalIcon className="h-5 w-5" />
                    </DropdownButton>
                    <DropdownMenu>
                        <DropdownItem onClick={handleNameEdit}>
                            <PencilIcon className="mr-2 h-5 w-5 text-gray-500" />
                            Edit
                        </DropdownItem>
                        <DropdownItem onClick={handleDeleteFolder}>
                            <TrashIcon className="mr-2 h-5 w-5 text-gray-500" />
                            Delete
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <div className="mb-6">
                {isEditingDescription ? (
                    <textarea
                        value={folderDescription}
                        onChange={handleDescriptionChange}
                        onBlur={handleDescriptionSubmit}
                        placeholder="Add a description of the purpose of your folder"
                        className="w-full p-2 text-base text-zinc-950 dark:text-white bg-transparent"
                        ref={descriptionRef}
                    />
                ) : (
                    <Subheading
                        className="cursor-pointer text-zinc-500 dark:text-zinc-400"
                        onClick={handleDescriptionEdit}
                    >
                        {folderDescription || "Add a description of the purpose of your folder"}
                    </Subheading>
                )}
            </div>
            <Divider />
            <div className="mt-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {relatedThreads.map(thread => (
                        <div 
                            key={thread.id} 
                            className="bg-white dark:bg-zinc-800 shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow duration-200 cursor-pointer"
                            onClick={() => navigate(`/thread/${thread.id}`)}
                        >
                            <div className="flex justify-between items-start">
                                <h3 className="text-lg text-zinc-900 dark:text-zinc-100">
                                    {thread.title}
                                </h3>
                                <Dropdown>
                                    <DropdownButton 
                                        as="div" 
                                        className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <EllipsisHorizontalIcon className="h-5 w-5" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        <DropdownItem onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            navigate(`/thread/${thread.id}`);
                                        }}>
                                            <PencilIcon className="mr-2 h-5 w-5" />
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            handleDeleteThread(thread);
                                        }}>
                                            <TrashIcon className="mr-2 h-5 w-5" />
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Folder;